<template>
  <div>
    <v-row>
      <v-col cols="12" md="2">
        <v-select
          :items="table_items"
          item-value="id"
          item-text="table_no"
          label="Table #"
          required
          :rules="rules.combobox_rule"
          v-model="table_id"
          dense
          outlined
          @change="get_data_unpaid"
        ></v-select>
      </v-col>
      <v-col cols="12" md="3">
        <h2>
          ({{ date_time }})Name: {{ name_of_customer }}
        </h2>
      </v-col>
      <v-col cols="12" md="3">
        <h2>
          Total: P {{ total_amount }} pesos only
        </h2>
      </v-col>
      <v-col cols="12" md="2">
        <v-btn
          class="w-full"
          v-if="liquidations.length > 0"
          color="primary" @click="is_payment = true">
          PAYMENT
        </v-btn>
      </v-col>
      <v-col cols="12" md="2">
        <v-btn
          class="w-full"
          v-if="liquidations.length > 0"
          color="warning" @click="void_data">
          VOID
        </v-btn>
      </v-col>
    </v-row>
    <v-simple-table dense>
      <template v-slot:default>
        <thead>
        <tr>
          <th class="text-center text-uppercase">
            MENU
          </th>
          <th class="text-center text-uppercase">
            AMOUNT
          </th>
          <th class="text-center text-uppercase">
            QUANTITY
          </th>
          <th class="text-center text-uppercase">
            TOTAL
          </th>
        </tr>
        </thead>
        <tbody>
        <tr
          v-for="item in liquidations"
          :key="item.id"
        >
          <td class="text-center">
            {{
              item.menu.title
            }}
          </td>
          <td class="text-center">
            {{ formatPrice(item.menu.amount) }}
          </td>
          <td class="text-center">
            {{ item.quantity }}
          </td>
          <td class="text-center">
            {{ formatPrice(item.total) }}
          </td>
        </tr>
        </tbody>
      </template>
    </v-simple-table>
    <v-dialog persistent v-model="is_payment" max-width="700px">
      <v-card>
        <v-toolbar dense dark color="primary">
          <v-toolbar-title
          ><h4 class="font-weight-light">PAYMENT SECTION</h4>
          </v-toolbar-title>
        </v-toolbar>
        <v-form ref="form" class="multi-col-validation mt-6">
          <v-card-text class="mt-4">
            <v-select
              v-model="type_of_payment"
              class="mb-4"
              dense
              outlined
              label="Type"
              :items="['CASH','GCASH','BANK TRANSFER']"
              :rules="rules.combobox_rule"
            ></v-select>

            <v-text-field
              v-if="type_of_payment==='BANK TRANSFER'"
              v-model="name"
              outlined
              label="Name"
              placeholder="JUAN DELA CRUZ"
              :rules="rules.non_empty_field_rule"
              dense
              required
            ></v-text-field>
            <v-text-field
              v-if="type_of_payment==='BANK TRANSFER'"
              v-model="details"
              outlined
              label="BANK TYPE"
              placeholder="BDO UNIBANK"
              :rules="rules.non_empty_field_rule"
              dense
              required
            ></v-text-field>
            <v-text-field
              label="Total Amount of Order"
              required
              outlined
              v-model="total_amount"
              readonly
            ></v-text-field>
            <v-row class="mt-3">
              <v-col
                md="12"
                cols="12"
              >
                <v-card-actions class="px-2">
                  Add Discount
                  <v-btn class="mx-2" fab dark small color="success"
                         @click="add_discount">
                    <v-icon dark>{{ icons.mdiPlus }}</v-icon>
                  </v-btn>
                  <v-divider class="mx-2"/>
                </v-card-actions>
              </v-col>
              <v-col
                md="12"
                cols="12"
              >
                <v-col v-for="(item, index) in discount_items" :key="index">
                  <v-card-actions>
                    <v-spacer/>
                    Remove Discount
                    <v-btn class="mx-2" fab dark small color="error"
                           @click="remove_discount(index)">
                      <v-icon dark>{{ icons.mdiClose }}</v-icon>
                    </v-btn>
                  </v-card-actions>
                  <v-row class="align-center mb-2">
                    <v-col md="4"
                           cols="12">
                      <v-select
                        v-model="item.type"
                        class="mx-2"
                        dense
                        outlined
                        label="Type"
                        :items="['GCCFI Member','Senior','Voucher']"
                        :rules="rules.combobox_rule"
                        @change="amend_discount(index,item.type,0)"
                      ></v-select>
                    </v-col>
                    <v-col md="4"
                           cols="12">
                      <v-text-field
                        v-if="item.type==='Senior'"
                        v-model="item.no_pax"
                        label="No. of Pax"
                        class="mx-2"
                        type="number"
                        :rules="rules.non_empty_field_rule"
                        @change="amend_discount(index,item.type,item.no_pax)"
                        @keyup="amend_discount(index,item.type,item.no_pax)"
                        required
                        dense
                      ></v-text-field>
                      <v-text-field
                        v-else-if="item.type==='GCCFI Member'"
                        v-model="item.percent"
                        label="Percent"
                        class="mx-2"
                        readonly
                        required
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col md="4"
                           cols="12">
                      <v-text-field
                        v-if="item.type==='Voucher'"
                        v-model="item.amount"
                        label="Amount"
                        class="mx-2"
                        required
                        dense
                        @change="amend_discount(index,item.type,item.amount)"
                        :rules="rules.default_max_45_character_and_no_empty_rule"
                      ></v-text-field>
                      <v-text-field
                        v-else
                        v-model="item.amount"
                        label="Amount"
                        class="mx-2"
                        required
                        readonly
                        dense
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-divider class="mx-2"/>
                </v-col>
              </v-col>

            </v-row>
            <v-text-field
              label="Total Amount of Order"
              required
              outlined
              v-model="grand_total_amount"
              readonly
            ></v-text-field>
            <v-text-field
              label="Clients Money"
              required
              outlined
              v-model="money"
              type="number"
              min="0"
              :rules="rules.default_max_45_character_and_no_empty_rule"
              @change="counter_money"
              @keyup="counter_money"
            ></v-text-field>
            <v-text-field
              label="Change"
              required
              outlined
              v-model="change"
              readonly
            ></v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-row>
              <!-- alert -->
              <v-col cols="12" v-show="alert">
                <v-alert
                  color="warning"
                  text
                  class="mb-0"

                >
                  <div class="d-flex align-start">
                    <v-icon color="warning">
                      {{ icons.mdiAlertOutline }}
                    </v-icon>

                    <div class="ms-3">
                      <p class="text-base font-weight-medium mb-1">
                        {{ alert_message }}
                      </p>
                    </div>
                  </div>
                </v-alert>
              </v-col>
              <v-col cols="12" md="12">
                <v-btn
                  v-if="change_raw>=0"
                  color="primary" class="w-full" @click="payment_initiate">
                  Save Changes
                </v-btn>
              </v-col>
              <v-col cols="12" md="12">
                <v-btn
                  color="error" class="w-full" @click="is_payment=false">
                  Close
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <snack-bar-dialog
      :snackbar_flag="this.snackbar"
      :color="this.snackbar_color"
      :snackbar_text="this.snackbar_text"
    />
  </div>
</template>

<script>
import {mdiAlertOutline, mdiCloudUploadOutline, mdiCheck, mdiPlus, mdiClose} from '@mdi/js'
import {mapGetters, mapActions} from "vuex";
import moment from 'moment'
import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'

const initialState = () => {
  return {
    liquidations: [],
    table_items: [],
    discount_items: [],

    is_payment: false,
    saving: false,
    alert: false,
    alert_message: '',
    money: '0',
    change: '0.00',
    change_raw: 0,
    total_amount: '0.00',
    grand_total_amount: '0.00',
    grand_total_amount_raw: 0,
    discount: 0,
    total_amount_raw: 0,
    table_id: '',
    date_and_time: '',
    name_of_customer: '',
    date_time: '',
    type_of_order: '',
    name: '',
    details: '',
    type_of_payment: '',
    table_no: '',
    is_deleting: false,
  };
};
export default {
  components: {
    snackBarDialog,
  },
  setup() {
    return {
      icons: {
        mdiClose,
        mdiPlus,
        mdiAlertOutline,
        mdiCloudUploadOutline,
        mdiCheck,
      },
    }
  },
  data() {
    return initialState()
  },
  mounted() {
    this.initialize_data()
  },
  computed: {
    ...mapGetters('form_rules', ['rules']),
    ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text', 'company_logo']),
    ...mapGetters('authentication', ['month_start', 'month_end', 'month_of', 'user_id', 'branch_id', 'branch', 'branch_contact_no', 'branch_address', 'company_name']),
  },
  methods: {
    ...mapActions('system_data', ['change_snackbar']),
    ...mapActions('table', ['get_for_payed_purchase_report', 'payment_of_table', 'void_payment_of_table']),
    ...mapActions('purchase_order_slip', ['create_otp_void_order', 'checker_otp']),
    initialize_data() {
      this.get_for_payed_purchase_report({
        branch_id: this.branch_id
      })
        .then(response => {
          this.table_items = response.data
        })
        .catch(error => {
          console.log(error)
        })
    },
    reset() {
      Object.assign(this.$data, initialState())
      this.initialize_data()
    },
    date_format(value) {
      return moment(value)
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(',', '.')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    counter_money() {
      var dis_amount = 0;
      var amount_raw = this.total_amount_raw;
      this.discount_items.forEach(function (item) {
        if (item.type === 'Voucher') {
          dis_amount += item.amount
        } else {
          dis_amount += amount_raw * (item.percent / 100)
        }
      })
      this.grand_total_amount_raw = (this.total_amount_raw - dis_amount)
      this.grand_total_amount = this.formatPrice(this.grand_total_amount_raw)
      this.change = '0.00';

      var a = parseFloat(this.grand_total_amount_raw) - parseFloat(this.money);
      this.change_raw = (a < 0 ? Math.abs(a) : -a)
      this.change = this.formatPrice(this.change_raw)
    },
    add_discount() {
      this.discount_items.push(
        {
          type: '',
          percent: '',
          amount: 0,
          no_pax: 0,
        }
      )
    },
    remove_discount(index) {
      this.discount_items.splice(index, index + 1)
      this.counter_money()
    },
    void_data() {
      const data = new FormData()
      data.append('company_name', this.company_name);
      data.append('user_id', this.user_id);
      data.append('branch_code', this.branch);
      this.create_otp_void_order(data)
        .then(response => {
          if (response.status === 201) {
            this.alert = true
            this.alert_message = response.data
            this.saving = false
          } else {
            let otp_input = prompt("Please enter OTP");
            if (otp_input == null || otp_input == "") {
              this.alert = true
              this.alert_message = 'Teller cancelled the prompt.'
              this.saving = false
            } else {
              data.append('otp', otp_input);
              this.checker_otp(data)
                .then(response => {
                  if (response.status === 201) {
                    this.alert = true
                    this.alert_message = response.data
                    this.saving = false
                  } else {
                    this.void_payment_of_table({
                      table_id: this.table_id,
                    })
                      .then(response => {
                        this.is_payment = false
                        var index = this.table_items.map(function (x) {
                          return x.id;
                        }).indexOf(this.table_id)
                        this.table_no = this.table_items[index].table_no
                        this.change_snackbar({
                          show: true,
                          color: response.status === 200 ? 'success' : 'error',
                          text: 'TABLE # ' + this.table_items[index].table_no + ' IS NOW VOID!',
                        })
                        this.type_of_payment = 'VOID'
                        this.print_receipt(response.data.reference_no, response.data.code,response.data.wifi_voucher_no)
                      })
                      .catch(error => {
                        console.log(error)
                      })
                  }
                })
                .catch(error => {
                  this.alert = true
                  this.alert_message = error
                  this.saving = false
                })
            }
          }
        })
        .catch(error => {
          this.alert = true
          this.alert_message = error
          this.saving = false
        })
    },
    async amend_discount(index, type, no_pax) {
      var percent = 0
      var amount = 0
      switch (type) {
        case "GCCFI Member":
          percent = 2
          break;
        case "Senior":
          percent = no_pax * 10.4
          break;
      }
      if (type === 'Voucher') {
        amount = no_pax
        no_pax = 0
      } else {
        amount = this.total_amount_raw * (percent / 100)
      }
      var is_removed = false
      var counyer = 0;
      await this.discount_items.forEach(function (item) {
        if (type === item.type) {
          counyer++
          if (counyer > 1) {
            is_removed = true
          }
        }
      })

      if (is_removed) {
        this.remove_discount(index)
      } else {
        this.discount_items[index] = {
          type: type,
          percent: percent,
          no_pax: no_pax,
          amount: amount,
        }
      }
      this.counter_money()
    },
    get_data_unpaid() {
      var index = this.table_items.map(function (x) {
        return x.id;
      }).indexOf(this.table_id)
      this.liquidations = this.table_items[index].purchase_order_slip_unpaid[0].list_of_orders
      this.name_of_customer = this.table_items[index].purchase_order_slip_unpaid[0].name_of_customer
      this.date_time = this.table_items[index].purchase_order_slip_unpaid[0].date
      this.type_of_order = this.table_items[index].purchase_order_slip_unpaid[0].type_of_order
      this.total_amount = '0.00'
      this.total_amount_raw = 0
      var tot = 0;
      this.liquidations.forEach(function (item) {
        tot += parseFloat(item.total)
      })
      this.total_amount_raw = tot
      this.total_amount = this.formatPrice(this.total_amount_raw)
      this.counter_money()
    },
    async payment_initiate() {
      if (this.$refs.form.validate()) {
        await this.payment_of_table({
          table_id: this.table_id,
          type_of_payment: this.type_of_payment,
          type_of_order: this.type_of_order,
          money: this.money,
          amount: this.total_amount_raw,
          change: this.change_raw,
          payment_name: this.name,
          payment_details: this.details,
          discount_items: JSON.stringify(this.discount_items),
        })
          .then(response => {
            this.is_payment = false
            var index = this.table_items.map(function (x) {
              return x.id;
            }).indexOf(this.table_id)
            this.table_no = this.table_items[index].table_no
            this.change_snackbar({
              show: true,
              color: response.status === 200 ? 'success' : 'error',
              text: 'TABLE # ' + this.table_items[index].table_no + ' IS NOW PAID!',
            })
            this.print_receipt(response.data.reference_no, response.data.code, response.data.wifi_voucher_no)
          })
          .catch(error => {
            console.log(error)
          })
      } else {
        this.alert = true
        this.alert_message = 'Please Fill up the field/s'
        this.saving = false
      }
    },
    async print_receipt(ref, code, wifi) {
      this.date_and_time = moment().format('YYYY-MM-DD HH:mm:ss')
      var title_array2 = []
      var head_array2 = []
      var data_array2 = []
      var discount_array2 = []
      var total_array2 = []
      var foot_array2 = []
      var witdss2 = [70, 41.5, 15, 41.5]
      var witdssTi2 = [192]
      var img_2 = this.company_logo
      var add_2 = this.branch_address
      var con_2 = this.branch_contact_no
      this.liquidations
      title_array2.push(
        [
          {image: img_2, width: 192, height: 90, style: 'logo'},
        ]
      )
      title_array2.push(
        [
          {text: add_2, style: 'subheader'},
        ]
      )
      title_array2.push(
        [
          {
            text: 'Contact # ' + con_2,
            style: 'subheader',
          },
        ]
      )
      head_array2.push(
        [
          {text: 'Branch: ' + this.branch, alignment: 'left'},
        ]
      )
      head_array2.push(
        [
          {text: 'Table #: ' + this.table_no, alignment: 'left',},
        ]
      )
      head_array2.push(
        [
          {text: 'Name: ' + this.name_of_customer, alignment: 'left',},
        ]
      )
      head_array2.push(
        [
          {text: 'Order Type: ' + this.type_of_order, alignment: 'left',},
        ]
      )
      head_array2.push(
        [
          {text: 'Mode of Payment: ' + this.type_of_payment, alignment: 'left',},
        ]
      )
      if (this.type_of_payment === 'BANK TRANSFER') {
        head_array2.push(
          [
            {text: this.name + ' (' + this.details + ')', alignment: 'left',},
          ]
        )
      }
      head_array2.push(
        [
          {text: 'Ref. No.: ' + ref, alignment: 'left',},
        ]
      )
      head_array2.push(
        [
          {text: 'Code No.: ' + code, alignment: 'left',},
        ]
      )
      head_array2.push(
        [
          {
            text: this.date_and_time,
            alignment: 'left'
          },
        ]
      )
      data_array2.push(
        [
          {text: 'Order', alignment: 'left'},
          {text: 'Amt', alignment: 'left'},
          {text: 'Pcs', alignment: 'left'},
          {text: 'Total', alignment: 'left'},
        ]
      )
      var gtotal2 = 0
      var ggtotal2 = this.grand_total_amount_raw
      var non_vat_amount2 = parseFloat(this.grand_total_amount_raw) * 0.03
      this.liquidations.forEach(function (item) {
        var tot = parseFloat(item.total)
        gtotal2 += tot
        data_array2.push(
          [
            {text: item.menu.title, alignment: 'left'},
            {text: item.menu.amount, alignment: 'left'},
            {text: item.quantity, alignment: 'left'},
            {
              text: (tot / 1).toFixed(2).replace(',', '.').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
              alignment: 'left'
            },
          ]
        )
      })
      data_array2.push(
        [
          {text: 'TOTAL', alignment: 'left'},
          {text: '', alignment: 'left'},
          {text: '', alignment: 'left'},
          {text: this.formatPrice(gtotal2), alignment: 'left'},
        ]
      )
      discount_array2.push(
        [
          {text: '', alignment: 'left'},
          {text: '', alignment: 'left'},
          {text: '', alignment: 'left'},
          {text: '', alignment: 'left'},
        ]
      )
      this.discount_items.forEach(function (item) {
        var dis_amount = gtotal2 * (item.percent / 100)
        discount_array2.push(
          [
            {text: item.type + ' Discount', alignment: 'left'},
            {
              text: item.type === 'Senior' || item.type === 'Voucher' ? '' : item.percent + ' %',
              alignment: 'left'
            },
            {text: '', alignment: 'left'},
            {
              text: ((item.type === 'Voucher' ? item.amount : dis_amount) / 1).toFixed(2).replace(',', '.').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
              alignment: 'left'
            },
          ]
        )
      })
      total_array2.push(
        [
          {text: '', alignment: 'left'},
          {text: '', alignment: 'left'},
          {text: '', alignment: 'left'},
          {text: '', alignment: 'left'},
        ]
      )
      total_array2.push(
        [
          {text: 'GRAND TOTAL', alignment: 'left'},
          {text: '', alignment: 'left'},
          {text: '', alignment: 'left'},
          {text: this.formatPrice(ggtotal2 - non_vat_amount2), alignment: 'left'},
        ]
      )
      total_array2.push(
        [
          {text: 'TAX (NON VAT)', alignment: 'left'},
          {text: '3%', alignment: 'left'},
          {text: '', alignment: 'left'},
          {text: this.formatPrice(non_vat_amount2), alignment: 'left'},
        ]
      )
      total_array2.push(
        [
          {text: 'TOTAL TO PAY', alignment: 'left'},
          {text: '', alignment: 'left'},
          {text: '', alignment: 'left'},
          {text: this.formatPrice(ggtotal2), alignment: 'left'},
        ]
      )

      total_array2.push(
        [
          {text: '', alignment: 'left'},
          {text: '', alignment: 'left'},
          {text: '', alignment: 'left'},
          {text: '', alignment: 'left'},
        ]
      )
      total_array2.push(
        [
          {text: '', alignment: 'left'},
          {text: '', alignment: 'left'},
          {text: '', alignment: 'left'},
          {text: '', alignment: 'left'},
        ]
      )
      total_array2.push(
        [
          {text: 'CASH', alignment: 'left'},
          {text: '', alignment: 'left'},
          {text: '', alignment: 'left'},
          {text: this.formatPrice(this.money), alignment: 'left'},
        ]
      )
      total_array2.push(
        [
          {text: 'CHANGE', alignment: 'left'},
          {text: '', alignment: 'left'},
          {text: '', alignment: 'left'},
          {text: this.formatPrice(this.change_raw), alignment: 'left'},
        ]
      )
      foot_array2.push(
        [
          {
            text: 'Note: This is not a valid Sales Invoice',
            style: 'subheader',
          },
        ]
      )
      // foot_array2.push(
      //   [
      //     {text: 'WE BRING THE SEA TO YOU', alignment: 'center'},
      //   ]
      // )
      foot_array2.push(
        [
          {text: 'Thank you and Come Again', alignment: 'center'},
        ]
      )
      foot_array2.push(
        [
          {text: 'YUMMIES CASHIER COPY', alignment: 'center', pageBreak: "after"},
        ]
      )

      var title_array3 = []
      var head_array3 = []
      var data_array3 = []
      var discount_array3 = []
      var total_array3 = []
      var foot_array3 = []
      var witdss3 = [70, 41.5, 15, 41.5]
      var witdssTi3 = [192]
      var img_3 = this.company_logo
      var add_3 = this.branch_address
      var con_3 = this.branch_contact_no
      title_array3.push(
        [
          {image: img_3, width: 192, height: 90, style: 'logo'},
        ]
      )
      title_array3.push(
        [
          {text: add_3, style: 'subheader'},
        ]
      )
      title_array3.push(
        [
          {
            text: 'Contact # ' + con_3,
            style: 'subheader',
          },
        ]
      )
      head_array3.push(
        [
          {text: 'Branch: ' + this.branch, alignment: 'left'},
        ]
      )
      head_array3.push(
        [
          {text: 'Table #: ' + this.table_no, alignment: 'left',},
        ]
      )
      head_array3.push(
        [
          {text: 'Name: ' + this.name_of_customer, alignment: 'left',},
        ]
      )

      if (wifi != '' && wifi != 'NA') {
        head_array3.push(
          [
            {text: 'WIFI VOUCHER: ' + wifi, alignment: 'center'},
          ]
        )
      }

      head_array3.push(
        [
          {text: 'Order Type: ' + this.type_of_order, alignment: 'left',},
        ]
      )
      head_array3.push(
        [
          {text: 'Mode of Payment: ' + this.type_of_payment, alignment: 'left',},
        ]
      )
      if (this.type_of_payment === 'BANK TRANSFER') {
        head_array3.push(
          [
            {text: this.name + ' (' + this.details + ')', alignment: 'left',},
          ]
        )
      }
      head_array3.push(
        [
          {text: 'Ref. No.: ' + ref, alignment: 'left',},
        ]
      )
      head_array3.push(
        [
          {text: 'Code No.: ' + code, alignment: 'left',},
        ]
      )
      head_array3.push(
        [
          {
            text: this.date_and_time,
            alignment: 'left'
          },
        ]
      )
      data_array3.push(
        [
          {text: 'Order', alignment: 'left'},
          {text: 'Amt', alignment: 'left'},
          {text: 'Pcs', alignment: 'left'},
          {text: 'Total', alignment: 'left'},
        ]
      )
      var gtotal3 = 0
      var ggtotal3 = this.grand_total_amount_raw
      var non_vat_amount3 = parseFloat(this.grand_total_amount_raw) * 0.03
      this.liquidations.forEach(function (item) {
        var tot = parseFloat(item.total)
        gtotal3 += tot
        data_array3.push(
          [
            {text: item.menu.title, alignment: 'left'},
            {text: item.menu.amount, alignment: 'left'},
            {text: item.quantity, alignment: 'left'},
            {
              text: (tot / 1).toFixed(2).replace(',', '.').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
              alignment: 'left'
            },
          ]
        )
      })
      data_array3.push(
        [
          {text: 'TOTAL', alignment: 'left'},
          {text: '', alignment: 'left'},
          {text: '', alignment: 'left'},
          {text: this.formatPrice(gtotal3), alignment: 'left'},
        ]
      )
      discount_array3.push(
        [
          {text: '', alignment: 'left'},
          {text: '', alignment: 'left'},
          {text: '', alignment: 'left'},
          {text: '', alignment: 'left'},
        ]
      )
      this.discount_items.forEach(function (item) {
        var dis_amount = gtotal3 * (item.percent / 100)
        discount_array3.push(
          [
            {text: item.type + ' Discount', alignment: 'left'},
            {text: item.type === 'Senior' ? '' : item.percent + ' %', alignment: 'left'},
            {text: '', alignment: 'left'},
            {
              text: (dis_amount / 1).toFixed(2).replace(',', '.').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
              alignment: 'left'
            },
          ]
        )
      })
      total_array3.push(
        [
          {text: '', alignment: 'left'},
          {text: '', alignment: 'left'},
          {text: '', alignment: 'left'},
          {text: '', alignment: 'left'},
        ]
      )
      total_array3.push(
        [
          {text: 'GRAND TOTAL', alignment: 'left'},
          {text: '', alignment: 'left'},
          {text: '', alignment: 'left'},
          {text: this.formatPrice(ggtotal3 - non_vat_amount3), alignment: 'left'},
        ]
      )
      total_array3.push(
        [
          {text: 'TAX (NON VAT)', alignment: 'left'},
          {text: '3%', alignment: 'left'},
          {text: '', alignment: 'left'},
          {text: this.formatPrice(non_vat_amount3), alignment: 'left'},
        ]
      )
      total_array3.push(
        [
          {text: 'TOTAL TO PAY', alignment: 'left'},
          {text: '', alignment: 'left'},
          {text: '', alignment: 'left'},
          {text: this.formatPrice(ggtotal3), alignment: 'left'},
        ]
      )

      total_array3.push(
        [
          {text: '', alignment: 'left'},
          {text: '', alignment: 'left'},
          {text: '', alignment: 'left'},
          {text: '', alignment: 'left'},
        ]
      )
      total_array3.push(
        [
          {text: '', alignment: 'left'},
          {text: '', alignment: 'left'},
          {text: '', alignment: 'left'},
          {text: '', alignment: 'left'},
        ]
      )
      total_array3.push(
        [
          {text: 'CASH', alignment: 'left'},
          {text: '', alignment: 'left'},
          {text: '', alignment: 'left'},
          {text: this.formatPrice(this.money), alignment: 'left'},
        ]
      )
      total_array3.push(
        [
          {text: 'CHANGE', alignment: 'left'},
          {text: '', alignment: 'left'},
          {text: '', alignment: 'left'},
          {text: this.formatPrice(this.change_raw), alignment: 'left'},
        ]
      )
      foot_array3.push(
        [
          {
            text: 'Note: This is not a valid Sales Invoice',
            style: 'subheader',
          },
        ]
      )
      // foot_array3.push(
      //   [
      //     {text: 'WE BRING THE SEA TO YOU', alignment: 'center'},
      //   ]
      // )
      foot_array3.push(
        [
          {text: 'Thank you and Come Again', alignment: 'center'},
        ]
      )
      foot_array3.push(
        [
          {text: 'CLIENTS COPY', alignment: 'center'},
        ]
      )
      //call pdfmake
      var pdfMake = require('pdfmake/build/pdfmake.js')
      if (pdfMake.vfs === undefined) {
        var pdfFonts = require('pdfmake/build/vfs_fonts.js')
        pdfMake.vfs = pdfFonts.pdfMake.vfs
      }
      var docDefinition = {
        pageOrientation: 'portrait',
        pageMargins: 1,
        content: [
          {
            style: {
              margin: [0, 2, 0, 2],
              fontSize: 12,
            },
            table: {
              widths: witdssTi2,
              body: title_array2,
            },
            layout: {
              hLineWidth: function (i, node) {
                return (i === 0 || i === node.table.body.length) ? 1 : 0;
              },
              vLineWidth: function (i, node) {
                return (i === 0 || i === node.table.widths.length) ? 1 : 0;
              },
            },
          },
          {
            style: {
              margin: [0, 2, 0, 2],
              fontSize: 10,
            },
            table: {
              widths: witdssTi2,
              body: head_array2,
            },
            layout: {
              hLineWidth: function (i, node) {
                return (i == 0 || i === node.table.body.length) ? 1 : 0;
              },
              vLineWidth: function (i, node) {
                return (i == 0 || i === node.table.widths.length) ? 1 : 0;
              },
            },
          },
          {
            style: {
              margin: [0, 2, 0, 2],
              fontSize: 9,
            },
            table: {
              widths: witdss2,
              body: data_array2,
            },
            layout: {
              fillColor: function (rowIndex) {
                return (rowIndex % 2 == 0) ? '#bae8cc' : null;
              },
              hLineWidth: function (i, node) {
                return (i == 0 || i === node.table.body.length) ? 1 : 0;
              },
              vLineWidth: function (i, node) {
                return (i == 0 || i === node.table.widths.length) ? 1 : 0;
              },
            },
          },
          {
            style: {
              margin: [0, 2, 0, 2],
              fontSize: 9,
            },
            table: {
              widths: witdss2,
              body: discount_array2,
            },
            layout: {
              fillColor: function (rowIndex) {
                return (rowIndex % 2 == 0) ? '#bae8cc' : null;
              },
              hLineWidth: function (i, node) {
                return (i == 0 || i === node.table.body.length) ? 1 : 0;
              },
              vLineWidth: function (i, node) {
                return (i == 0 || i === node.table.widths.length) ? 1 : 0;
              },
            },
          },
          {
            style: {
              margin: [0, 2, 0, 2],
              fontSize: 9,
            },
            table: {
              widths: witdss2,
              body: total_array2,
            },
            layout: {
              fillColor: function (rowIndex) {
                return (rowIndex % 2 == 0) ? '#bae8cc' : null;
              },
              hLineWidth: function (i, node) {
                return (i == 0 || i === node.table.body.length) ? 1 : 0;
              },
              vLineWidth: function (i, node) {
                return (i == 0 || i === node.table.widths.length) ? 1 : 0;
              },
            },
          },
          {
            style: {
              margin: [0, 2, 0, 2],
              fontSize: 9,
            },
            table: {
              widths: witdssTi2,
              body: foot_array2,
            },
            layout: {
              hLineWidth: function (i, node) {
                return (i == 0 || i === node.table.body.length) ? 1 : 0;
              },
              vLineWidth: function (i, node) {
                return (i == 0 || i === node.table.widths.length) ? 1 : 0;
              },
            },
          },
          ,
          {
            style: {
              margin: [0, 2, 0, 2],
              fontSize: 12,
            },
            table: {
              widths: witdssTi3,
              body: title_array3,
            },
            layout: {
              hLineWidth: function (i, node) {
                return (i === 0 || i === node.table.body.length) ? 1 : 0;
              },
              vLineWidth: function (i, node) {
                return (i === 0 || i === node.table.widths.length) ? 1 : 0;
              },
            },
          },
          {
            style: {
              margin: [0, 2, 0, 2],
              fontSize: 10,
            },
            table: {
              widths: witdssTi3,
              body: head_array3,
            },
            layout: {
              hLineWidth: function (i, node) {
                return (i == 0 || i === node.table.body.length) ? 1 : 0;
              },
              vLineWidth: function (i, node) {
                return (i == 0 || i === node.table.widths.length) ? 1 : 0;
              },
            },
          },
          {
            style: {
              margin: [0, 2, 0, 2],
              fontSize: 9,
            },
            table: {
              widths: witdss3,
              body: data_array3,
            },
            layout: {
              fillColor: function (rowIndex) {
                return (rowIndex % 2 == 0) ? '#bae8cc' : null;
              },
              hLineWidth: function (i, node) {
                return (i == 0 || i === node.table.body.length) ? 1 : 0;
              },
              vLineWidth: function (i, node) {
                return (i == 0 || i === node.table.widths.length) ? 1 : 0;
              },
            },
          },
          {
            style: {
              margin: [0, 2, 0, 2],
              fontSize: 9,
            },
            table: {
              widths: witdss3,
              body: discount_array3,
            },
            layout: {
              fillColor: function (rowIndex) {
                return (rowIndex % 2 == 0) ? '#bae8cc' : null;
              },
              hLineWidth: function (i, node) {
                return (i == 0 || i === node.table.body.length) ? 1 : 0;
              },
              vLineWidth: function (i, node) {
                return (i == 0 || i === node.table.widths.length) ? 1 : 0;
              },
            },
          },
          {
            style: {
              margin: [0, 2, 0, 2],
              fontSize: 9,
            },
            table: {
              widths: witdss3,
              body: total_array3,
            },
            layout: {
              fillColor: function (rowIndex) {
                return (rowIndex % 2 == 0) ? '#bae8cc' : null;
              },
              hLineWidth: function (i, node) {
                return (i == 0 || i === node.table.body.length) ? 1 : 0;
              },
              vLineWidth: function (i, node) {
                return (i == 0 || i === node.table.widths.length) ? 1 : 0;
              },
            },
          },
          {
            style: {
              margin: [0, 2, 0, 2],
              fontSize: 9,
            },
            table: {
              widths: witdssTi3,
              body: foot_array3,
            },
            layout: {
              hLineWidth: function (i, node) {
                return (i == 0 || i === node.table.body.length) ? 1 : 0;
              },
              vLineWidth: function (i, node) {
                return (i == 0 || i === node.table.widths.length) ? 1 : 0;
              },
            },
          },
        ],
        footer: {},
        styles: {
          header: {
            fontSize: 21,
            bold: true,
            alignment: 'left',
            margin: [6, 6, 0, 5], //[left, top, right, bottom]
          },
          subheader: {
            fontSize: 7,
          },
          logo: {
            alignment: 'center',
            margin: [0, 0, 0, 0], //[left, top, right, bottom]
          },
          printed_label: {
            alignment: 'right',
            fontSize: 8,
            margin: [0, 0, 0, 0], //[left, top, right, bottom]
          },
          main_data: {
            margin: [0, 2, 0, 2],
            fontSize: 11,
            bold: true,
            alignment: 'left',
          },
          main_data_2: {
            margin: [0, 2, 0, 2],
            fontSize: 8,
            bold: true,
            alignment: 'left',
          },
          main_info: {
            margin: [0, 2, 0, 2],
            fontSize: 10,
            alignment: 'left',
          },
        },
      }
      var win = window.open('', '', "width=1000,height=500");
      pdfMake.createPdf(docDefinition).print({}, win)
      this.reset()
    },
  }
}
</script>
